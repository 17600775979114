import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {useRequest} from "ahooks";
import RequireApi from "../../util/api";
import qs from "qs";
import QuestionOption from "./question-option";
import './index.css';
import Tools from "../../util/tools";
import Loading from "../../component/loading";
import Zoomer from "../../component/zoomer";

export default () => {
    const location = useLocation();
    const search = qs.parse(location.search, {ignoreQueryPrefix: true});
    const [fontOffset, setFontOffset] = useState();

    const {data: data} = useRequest(() => {
        return new Promise((resolve, reject) => {
            if (search.id) {
                RequireApi.GetQuestion({id: search.id}, {Authorization: search.Authorization})
                    .then(({data}) => resolve(data))
                    .catch(err => reject(err));
            } else {
                RequireApi.RandomQuestion({}, {Authorization: search.Authorization})
                    .then(({data}) => {
                        console.log(data.id)
                        Tools.SendUnityMessage('setTitle', {title: data.title});
                        Tools.SendUnityMessage('setRoute', {route: `question?id=${data.id}`})
                        resolve(data);
                    })
                    .catch(err => reject(err));
            }
        })
    })

    const QuestionBody = () => {
        return (
            <div className="question-body">
                <p className="question-title acupoint-title" style={{fontSize: (24 + fontOffset) + 'pt'}}>{data.title}</p>
                {data.options.map(o =>
                    <QuestionOption fontSize={(16 + fontOffset) + 'pt'} key={o.id} text={o.value[0].name}
                                    correct={o.correct}/>)}
                <Zoomer zoomKey='font-offset' onChange={(v) => setFontOffset(v)}/>
            </div>
        )
    }

    return (
        <div>
            {data ? <QuestionBody/> : <Loading/>}
        </div>
    )
}