import React from "react";
import "./index.css";
import Add from "../../image/add.png";
import Reduce from "../../image/reduce.png";
import {useLocalStorageState, useMount} from "ahooks";

export default ({zoomKey, onChange}) => {
    const [zoomValue, setZoomValue] = useLocalStorageState(zoomKey, {defaultValue: 0});

    useMount(() => {
        onChange(zoomValue);
    })

    const updateFontSize = (delta) => {
        let size = zoomValue + delta;
        // font size 默认大小是20px 所以限制 fontOffset的范围在 -5~20
        // 所以font size的实际大小范围是 15~40
        if (size < -5 || size > 20)
            return;
        setZoomValue(size);
        onChange(size);
    }

    const ZoomButton = ({image, onClick}) => {
        return (
            <div className="zoom-button" onClick={onClick}>
                <img src={image} alt=""/>
            </div>
        )
    }

    return (
        <div>
            <div className="zoomer">
                <ZoomButton image={Add} onClick={() => updateFontSize(5)}/>
                <ZoomButton image={Reduce} onClick={() => updateFontSize(-5)}/>
            </div>
        </div>
    )
}