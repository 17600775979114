import React from "react";
import Loading from '../../image/loading.png';
import "./index.css";

export default () => {
    return (
        <div className="full-box">
            <span style={{flex: 1}}/>
            <div className="loading-box">
                <img src={Loading} alt="loading"/>
                <span>加载中...</span>
            </div>
            <span style={{flex: 2}}/>
        </div>
    )
}