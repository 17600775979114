import React, {useState} from "react";
import './index.css';
import {useRequest} from "ahooks";
import RequireApi from "../../util/api";
import Tools from "../../util/tools";
import qs from "qs";
import {useLocation} from "react-router-dom";
import Loading from "../../component/loading";
import Zoomer from "../../component/zoomer";

export default () => {
    const location = useLocation();
    const search = qs.parse(location.search, {ignoreQueryPrefix: true});
    const [fontOffset, setFontOffset] = useState();

    const {data: data} = useRequest(() => {
        return new Promise((resolve, reject) => {
            if (search.title) {
                RequireApi.GetBDByTitle({title: search.title})
                    .then(({data}) => resolve(data))
                    .catch(err => reject(err));
            } else {
                RequireApi.RandomBD()
                    .then(({data}) => {
                        Tools.SendUnityMessage('setTitle', {title: data.title});
                        Tools.SendUnityMessage('setRoute', {route: `article?title=${data.title}`});
                        resolve(data);
                    })
                    .catch(err => reject(err));
            }
        })
    });

    const ArticleBody = () => {
        return (
            <div className="article-body">
                <h1 className="acupoint-title">{data.title}</h1>
                <p className="view-sum">{`阅读数：${data.view_sum}`}</p>
                <div className="article-content" style={{fontSize: (16 + fontOffset) + 'pt'}}
                     dangerouslySetInnerHTML={{__html: data.content}}/>
                <Zoomer zoomKey='font-offset' onChange={(v) => setFontOffset(v)}/>
            </div>
        )
    }

    return (
        <div>
            {data ? <ArticleBody/> : <Loading/>}
        </div>
    )
}