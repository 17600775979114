import React from "react";
import Article from "../article";
import Question from "../question";
import Tools from "../../util/tools";
import {useLocation} from "react-router-dom";
import qs from "qs";

export default () => {
    const location = useLocation();
    const search = qs.parse(location.search, {ignoreQueryPrefix: true});

    const routeWeight = [
        {element: <Article/>, weight: 5, needToken: false},
        {element: <Question/>, weight: 1, needToken: true},
    ];

    const RandomRoute = () => {
        const hasToken = !!search.Authorization;
        const list = hasToken ? routeWeight : routeWeight.filter(i => !i.needToken);
        const max = list.length === 1 ? list[0].weight : list.reduce((pre, cur) =>
            pre.weight + cur.weight);

        let result = Tools.RandomInt(0, max);
        for (let i = 0, start = 0; i < routeWeight.length; i++) {
            let bound = start + routeWeight[i].weight;
            if (result >= start && result < bound) {
                return routeWeight[i].element;
            }
            start = bound;
        }
        return null;
    }

    return (
        <RandomRoute/>
    )
}