import './App.css';
import {Route, Routes} from "react-router-dom";
import Article from './view/article';
import Question from './view/question';
import Crossing from './view/crossing';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/article" element={<Article/>}/>
                <Route path="/question" element={<Question/>}/>
                <Route path="/" element={<Crossing/>}/>
            </Routes>
        </div>
    );
}

export default App;
