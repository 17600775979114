import request from "../request";

export class Api  {

    RandomWiki = (body) => {
        return request.connection('get', 'cms/app/wiki/random', body);
    };

    GetWikiByTitle = (body) => {
        return request.connection('get', `cms/browser/wiki/${body.title}`, {})
    }

    RandomBD = (body) => {
        return request.connection('get', 'cms/app/中医百度/random', body);
    }

    GetBDByTitle = (body) => {
        return request.connection('get', `cms/browser/中医百度/${body.title}`, {})
    }

    RandomQuestion = (body, headers) => {
        return request.connection('get', `sqec/app/question/random/`, body, headers);
    }

    GetQuestion = (body, headers) => {
        return request.connection('get', `sqec/app/question/${body.id}`, {}, headers)
    }

}

const RequireApi = new Api();
export default RequireApi;