import qs from "qs";

export default class Tools {
    static SendUnityMessage(path, data) {
        const url = `uniwebview://${path}?${qs.stringify(data)}`;
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static RandomInt(start, end) {
        return parseInt(Math.random() * (end - start) + start);
    }
}